<script>
export default {
  render() {
    return this.$scopedSlots.default({
      
    })
  },

  inject: ['svc'],

  provide: function() {
    return {
      svc: () => this.svc,
    }
  }
}
</script>